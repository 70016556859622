import { useState, useCallback, useEffect } from 'react'
import { history } from 'umi';

import { getOperatorInfo, getGuestOperatorInfo, getMappingList } from "@/services/public"
import { getToken, setToken, removeToken } from "@/tools/auth"

interface statusTS {
  staff: number, enterprise: number, cooperation: number
}

export default function useInfoModel() {
  const [userInfo, setUserInfo] = useState<any>(null)
  const [permissions, setPermissions] = useState<any>(null)
  const [enterprise, setEnterprise] = useState<any>(null)
  const [industrEnList, setIndustrEnList] = useState<any>(null)
  const [authStaff, setAuthStaff] = useState<Boolean>(false) //员工认证
  const [mappingList, setMappingList] = useState<Array<any>>([]) //请求设备数据使用  

  // 0：未申请(前端定义)  10:审核中  20：审核通过  30：审核不通过
  const [authTypeStatus, setAuthTypeStatus] = useState<statusTS>({ staff: 0, enterprise: 0, cooperation: 0 }) //认证 类型的状态   staff:员工  enterprise：企业  cooperation：合作伙伴

  useEffect(() => {
    if (getToken() && getToken() !== "nobody" && !userInfo) {
      getUserInfo()
    } else if (!getToken()) {
      setToken("nobody")
    }
    // getGuestAuth()
  }, [])

  const getGuestAuth = () => {
    getGuestOperatorInfo().then((res) => {

    })
  }

  const getUserInfo = () => {
    getOperatorInfo().then((res) => {
      if (res && res.Success) {
        setUserInfo(res.Data.UserInfo)
        setPermissions(res.Data.Permissions)
        setEnterprise(res.Data.Enterprise.Id?res.Data.Enterprise:null)
        setIndustrEnList(res.Data.IndustrEnList ? res.Data.IndustrEnList : [])

        //员工认证 判断
        if (res.Data.Enterprise.Id && res.Data.Enterprise.UserId != res.Data.UserInfo.Id) {
          setAuthStaff(true)
        }
        if (res.Data.UserInfo && res.Data.UserInfo.AuthenticationStatus == 10 && !res.Data.Enterprise.Id) {
          setAuthStaff(true)
        }
        if (res.Data.UserInfo && res.Data.UserInfo.AuthenticationStatus == 20) {
          if (!res.Data.Enterprise.Id || (res.Data.Enterprise.Id && res.Data.Enterprise.UserId != res.Data.UserInfo.Id)) {
            setAuthStaff(true)
          } else {
            setAuthStaff(false)
          }
        }
        if (res.Data.UserInfo && res.Data.UserInfo.AuthenticationStatus == 30) {
          if (!res.Data.Enterprise.Id || (res.Data.Enterprise.Id && res.Data.Enterprise.UserId != res.Data.UserInfo.Id)) {
            setAuthStaff(true)
          } else {
            setAuthStaff(false)
          }
        }

        //认证类型 的状态 0：未申请  10:审核中  20：审核通过  30：审核不通过
        // 员工认证 判断

        let authTypeStatus = { staff: 0, enterprise: 0, cooperation: 0 }
        if ((res.Data.UserInfo && res.Data.UserInfo.AuthenticationStatus == 10 && !res.Data.Enterprise.Id) || (res.Data.Enterprise.Id && res.Data.Enterprise.UserId != res.Data.UserInfo.Id)) {
          authTypeStatus.staff = 10
        }
        if ((res.Data.UserInfo && res.Data.UserInfo.AuthenticationStatus == 20 && !res.Data.Enterprise.Id) || (res.Data.Enterprise.Id && res.Data.Enterprise.UserId != res.Data.UserInfo.Id)) {
          authTypeStatus.staff = 20
        }
        if (res.Data.UserInfo && res.Data.UserInfo.AuthenticationStatus == 30 && (!res.Data.Enterprise.Id || (res.Data.Enterprise.Id && res.Data.Enterprise.UserId != res.Data.UserInfo.Id))) {
          authTypeStatus.staff = 30
        }

        //企业认证  判断
        if (res.Data.Enterprise.Id) {
          if (res.Data.Enterprise.EnterpriseType == '10' && res.Data.Enterprise.Status == 10) {
            authTypeStatus.enterprise = 10
          }
          if (res.Data.Enterprise.EnterpriseType == '10' && res.Data.Enterprise.Status == 20) {
            authTypeStatus.enterprise = 20
          }
          if (res.Data.Enterprise.EnterpriseType == '10' && res.Data.Enterprise.Status == 30) {
            authTypeStatus.enterprise = 30
          }
        }
        //合伙认证  判断
        if (res.Data.Enterprise.Id) {
          if (res.Data.Enterprise.EnterpriseType == '20' && res.Data.Enterprise.Status == 10) {
            authTypeStatus.cooperation = 10
          }
          if (res.Data.Enterprise.EnterpriseType == '20' && res.Data.Enterprise.Status == 20) {
            authTypeStatus.cooperation = 20
          }
          if (res.Data.Enterprise.EnterpriseType == '20' && res.Data.Enterprise.Status == 30) {
            authTypeStatus.cooperation = 30
          }
        }
        setAuthTypeStatus(authTypeStatus)
      }
    })
  }

  const getMappingListFunc = (uniformSocialCreditCode: string) => {
    setMappingList([])
    getMappingList(uniformSocialCreditCode).then((res: any) => {
      if (res && res.tszMappings) {
        setMappingList(res.tszMappings)
      }
    })
  }

  const updataInfo = useCallback(() => {
    getUserInfo()
  }, [])

  // const signin = useCallback((account, password) => {
  //   // signin implementation
  //   // setUser(user from signin API)
  // }, [])

  const signout = useCallback(() => {
    setToken("nobody")
    setUserInfo(null)
    setPermissions(null)
    setEnterprise(null)
    setIndustrEnList(null)
    history.push("/index")
  }, [])

  return {
    userInfo, permissions, enterprise, industrEnList, updataInfo, signout, authStaff, authTypeStatus, mappingList, getMappingListFunc
  }
}

// 使用示例
// import { useModel } from 'umi';

// export default () => {
//   const { user, fetchUser } = useModel('user', model => ({ user: model.user, fetchUser: model.fetchUser }))
//   return <>hello</>
// };
import request from '@/tools/fecth';

const reqDomain = window.configUrl().reqDomain;
const newBaseURL = reqDomain[60201]
const newBaseURL2 = reqDomain[50029]

// 获取企业成员管理列表
export function getAdviceList(query:Object) {
    return request(`/Base_Manage/Base_User/GetEnterpriseUserList`,{
        method: 'post',
        data: query
    });
}

// 单个用户审核
export function submitCheck(query:Object) {
    return request(`/Base_Manage/Base_User/ExameUser`,{
        method: 'post',
        data: query
    });
}

// 获取对应企业设备信息
export function getEnterpriseMapping(query:string) {
    return request(newBaseURL2 + `/system/enterpriseMapping/getEnterpriseMappingList?uniformSocialCreditCode=`+ query,{
        method: 'get',
        data: {
            prefixUrl: true,
            delToken: true
        }
    });
}

// 判断是否订购设备情况
export function ChkEnterpriseBuyKanban(query:any) {
    return request(`/Business_Manage/Bus_Enterprise/ChkEnterpriseBuyKanban?Id=${query}`,{
        method: 'post',
        // data: query
    });
}

// 获取设备情况
export function getEquipmentInfo(query:Object) {
    return request(newBaseURL + `/remote/equipment/getDataInfo`,{
        method: 'post',
        data: query
    });
}

// 获取合作伙伴产品相关数据
export function getCooperationInfo(query:string) {
    return request(newBaseURL + `/portalManage/serviceManage/getServiceAttrCount?enterpriseId=` + query,{
        method: 'get',
        data: {
            prefixUrl: true,
            delToken: true
        }
    });
}

// 消息中心-获取消息列表
export function getMessagesList(query:object) {
    return request( `/Business_Manage/Bus_Message_Manage/GetWorkMessageList`,{
        method: 'post',
        data: query
    });
}

// 消息中心-获取未读消息数
export function getUnreadMsgCount(query:object) {
    return request( `/Business_Manage/Bus_Message_Manage/GetUnreadMessageTotal`,{
        method: 'post',
        data: query
    });
}

// 消息中心-删除单条消息
export function deleteThisMessage(query:object) {
    return request( `/Business_Manage/Bus_Message_Manage/DeleteData`,{
        method: 'post',
        data: query
    });
}

// 消息中心-更新消息状态为已读
export function updateMsgStatus(query:object) {
    return request( `/Business_Manage/Bus_Message_Manage/EditUnreadStatus`,{
        method: 'post',
        data: query
    });
}

// 获取应用列表
export function getApplications(query:string) {
    return request( `/Business_Manage/Bus_Enterprise_AddConfig/GetEnterpriseUrl?WorkConfigType=10&EnterpriseID=${query}`,{
        method: 'post',
        // data: {
        //     prefixUrl: true,
        //     delToken: true
        // }
    });
}

// 获取密文
export function getApplicationToken(query:object) {
    return request( `/Business_Manage/Bus_Enterprise_AddConfig/GetToken`,{
        method: 'post',
        data: query
    });
}


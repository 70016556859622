import { useState, useCallback, useEffect } from 'react'
import { getUnreadMsgCount } from '@/services/workbench'
import { useModel } from 'umi';

export default function useNotice() {

    const [unreadMsgCount, setUnreadMsgCount] = useState(0)

    const { userInfo } = useModel('useUserInfoModel', model => ({ userInfo: model.userInfo }))

    useEffect(() => {
        if (userInfo&&userInfo.EnterpriseID&&userInfo.EnterpriseType) {
          getMsgCount()
        }
      }, [userInfo])

    const getMsgCount = () => {
        // console.log(111)
        let submitobj = {
          Search: {
            EnterpriseID: userInfo.EnterpriseID,
            UserID: userInfo.Id,
            EnterpriseType: userInfo.EnterpriseType
          }
        }
        getUnreadMsgCount(submitobj).then(res => {
          if (res && res.Success) {
            setUnreadMsgCount(res.Data)
          }
        })
    }

    return {
        unreadMsgCount, getMsgCount
      }
}
import request from '../tools/fecth';

const reqDomain = window.configUrl().reqDomain;
const newBaseURL = reqDomain[60201]

// 获取用户信息
export function getOperatorInfo() {
    return request(`/Base_Manage/Home/GetOperatorInfo`,{
        method: 'post',
        data: {}
    });
}
// 获取访客 token(暂不能使用)
export function getGuestOperatorInfo() {
    return request(`/Base_Manage/Home/GetGuestOperatorInfo`,{
        method: 'post',
        data: {}
    });
}

// 获取企业的 mappingList  (采集的设备数据使用)
export function getMappingList(query:string) {
    return request(reqDomain[50029] + `/system/userEnterpriseMapping/getUserEnterpriseMappingList?uniformSocialCreditCode=${query}`,{
        method: 'get',
        data: {
            prefixUrl: true,
            delToken: true
        }
    });
}

// 企业ID获取企业信息
export function getEnterpriseInfo(query:object) {
    return request(`/Business_Manage/Bus_Enterprise/GetTheData`,{
        method: 'post',
        data: query
    });
}

//纺织云app 二维码
export function VerifyCode() {
    return request(`/api/ILogin/VerifyCode`,{
        method: 'get'
    });
}

//省类表
export function getAreaTreeModelsUp(query:Object) {
    return request(`/Base_Manage/Base_Area/GetAreaTreeModelsUp`,{
        method: 'post',
        data:query
    });
}
//某个省ID：获取这个省下的所有城市
export function getAreaTreeProvince(query:Object) {
    return request(`/Base_Manage/Base_Area/GetAreaTreeProvince`,{
        method: 'post',
        data:query
    });
}
//获取某个区县下所有的乡镇街道信息
export function getAreaTreeStree(query:Object) {
    return request("/Base_Manage/Base_Area/GetAreaTreeStree",{
        method: 'post',
        data:query
    });
}

//根据区域ID，获取省份+市+区县信息
export function getAreaInfo(query:Object) {
    return request("/Base_Manage/Base_Area/GetAreaInfo",{
        method: 'post',
        data:query
    });
}

// 获取优惠券的 NO
export function postFlowNo(query:Object) {
    return request("/Business_Manage/Enterprise_Apply_Subsidy/GetFlowNo",{
        method: 'post',
        data:query
    });
}
// 批量获取优惠券的 NO
export function postFlowsNo(query:Array<string>) {
    return request("/Business_Manage/Enterprise_Apply_Subsidy/GetFlowsNo",{
        method: 'post',
        data:query
    });
}

// 标识解析查询
export function queryIdentityvByHandle(query:string) {
    return request(newBaseURL + `/register/identityNew/queryIdentityvByHandle?handle=${query}`,{
        method: 'get',
        data: {
            prefixUrl: true,
            delToken: true
        }
    });
}

// 获取首页banner
export function getShowBannerList() {
    return request(newBaseURL + `/portalManage/busBanner/getShowBannerList/01`,{
        method: 'get',
        data: {
            prefixUrl: true,
            delToken: true
        }
    });
}

//获取行业类目
export function getItemDetailList(query:Object) {
    return request(`/System_Manage/System_DataItemDetail/GetItemDetailList`,{
        method: 'post',
        data: query
    })
}
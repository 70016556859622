// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from 'F:/长乐区工业互联网平台前端/textile/node_modules/umi/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';

export function getRoutes() {
  const routes = [
  {
    "path": "/agreementAddress",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__agreementPage__agreementPage' */'@/pages/agreementPage/agreementPage')}),
    "exact": true,
    "title": "协议",
    "search": false
  },
  {
    "path": "/noPermission",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__noPermission__noPermission' */'@/pages/noPermission/noPermission')}),
    "exact": true,
    "title": "无权限访问",
    "search": false
  },
  {
    "path": "/workbench",
    "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/wrappers/auth')})],
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__index' */'@/layouts/index')}),
    "routes": [
      {
        "path": "/workbench",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__workbench__enterprise_account__workbench__workbench' */'@/pages/workbench/enterprise_account/workbench/workbench')}),
        "exact": true,
        "title": "工作台首页",
        "search": true
      },
      {
        "path": "/workbench/changePassword",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__workbench__enterprise_account__changePassword__changePassword' */'@/pages/workbench/enterprise_account/changePassword/changePassword')}),
        "exact": true,
        "title": "修改密码",
        "search": true
      },
      {
        "path": "/workbench/productOrdering",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__workbench__enterprise_account__productOrdering__productOrdering' */'@/pages/workbench/enterprise_account/productOrdering/productOrdering')}),
        "exact": true,
        "title": "产品订购",
        "search": true
      },
      {
        "path": "/workbench/consulting",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__workbench__enterprise_account__consulting__consulting' */'@/pages/workbench/enterprise_account/consulting/consulting')}),
        "exact": true,
        "title": "咨询与投诉",
        "search": true
      },
      {
        "path": "/workbench/notice",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__workbench__enterprise_account__notice__notice' */'@/pages/workbench/enterprise_account/notice/notice')}),
        "exact": true,
        "title": "通知公告",
        "search": true
      },
      {
        "path": "/workbench/authenticationHandle",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__workbench__enterprise_account__workbench__authenticationHandle__authentication' */'@/pages/workbench/enterprise_account/workbench/authenticationHandle/authentication')}),
        "exact": true,
        "title": "企业认证",
        "search": false
      },
      {
        "path": "/workbench/authenticationPartner",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__workbench__enterprise_account__workbench__authenticationPartner__authenticationPartner' */'@/pages/workbench/enterprise_account/workbench/authenticationPartner/authenticationPartner')}),
        "exact": true,
        "title": "合作伙伴认证",
        "search": false
      },
      {
        "path": "/workbench/identification",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__workbench__enterprise_account__identification__identification' */'@/pages/workbench/enterprise_account/identification/identification')}),
        "exact": true,
        "title": "二级标识解析",
        "search": true
      },
      {
        "path": "/workbench/subsidyApply",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__workbench__enterprise_account__subsidyApply__subsidyApply' */'@/pages/workbench/enterprise_account/subsidyApply/subsidyApply')}),
        "exact": true,
        "title": "补贴申请"
      },
      {
        "path": "/workbench/memberManagement",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__workbench__enterprise_account__memberManagement__memberManagement' */'@/pages/workbench/enterprise_account/memberManagement/memberManagement')}),
        "exact": true,
        "title": "企业成员管理",
        "search": true
      },
      {
        "path": "/workbench/messageCenter",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__workbench__enterprise_account__messageCenter__messageCenter' */'@/pages/workbench/enterprise_account/messageCenter/messageCenter')}),
        "exact": true,
        "title": "消息中心",
        "search": true
      },
      {
        "path": "/workbench/informationManagement",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__workbench__enterprise_account__informationManagement__informationManagement' */'@/pages/workbench/enterprise_account/informationManagement/informationManagement')}),
        "exact": true,
        "title": "信息管理",
        "search": true
      },
      {
        "path": "/workbench/enterpriseInfo",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__workbench__enterprise_account__workbench__enterpriseInfo__enterpriseInfo' */'@/pages/workbench/enterprise_account/workbench/enterpriseInfo/enterpriseInfo')}),
        "exact": true,
        "title": "查看企业信息",
        "search": false
      },
      {
        "path": "/workbench/productManage",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__workbench__cooperation_account__productManage__productManage' */'@/pages/workbench/cooperation_account/productManage/productManage')}),
        "exact": true,
        "title": "产品管理",
        "search": true
      },
      {
        "path": "/workbench/releaseProduct",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__workbench__cooperation_account__releaseProduct__releaseProduct' */'@/pages/workbench/cooperation_account/releaseProduct/releaseProduct')}),
        "exact": true,
        "title": "发布产品",
        "search": true
      }
    ]
  },
  {
    "path": "/specialAccess",
    "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/wrappers/specialAuth')})],
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__divLayout' */'@/layouts/divLayout')}),
    "routes": [
      {
        "path": "/specialAccess/dataScreen",
        "microApp": "screen",
        "microAppProps": {
          "autoSetLoading": true,
          "wrapperClassName": "myWrapper"
        },
        "exact": false,
        "title": "福建（长乐）纺织工业互联网平台",
        "search": false,
        "component": (() => {
          const { getMicroAppRouteComponent } = umiExports;
          return getMicroAppRouteComponent({ appName: 'screen', base: '/textileindustry', masterHistoryType: 'hash', routeProps: {'settings':{},'autoSetLoading':true,'wrapperClassName':'myWrapper'} })
        })()
      }
    ]
  },
  {
    "path": "/currency",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__divLayout' */'@/layouts/divLayout')}),
    "routes": [
      {
        "path": "/currency/supplyChainFinance",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__workbench__supply_chain_finance__index__index' */'@/pages/workbench/supply_chain_finance/index/index')}),
        "exact": true,
        "title": "供应链金融",
        "search": true
      },
      {
        "path": "/currency/supplyChainFinance/measurement",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__workbench__supply_chain_finance__Accurate_measurement__measurement' */'@/pages/workbench/supply_chain_finance/Accurate_measurement/measurement')}),
        "exact": true,
        "title": "精准测评",
        "search": true
      },
      {
        "path": "/currency/supplyChainFinance/tax_assessment",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__workbench__supply_chain_finance__tax_assessment__tax_assessment' */'@/pages/workbench/supply_chain_finance/tax_assessment/tax_assessment')}),
        "exact": true,
        "title": "以税测评",
        "search": true
      },
      {
        "path": "/currency/supplyChainFinance/housing_evaluation",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__workbench__supply_chain_finance__housing_evaluation__housing_evaluation' */'@/pages/workbench/supply_chain_finance/housing_evaluation/housing_evaluation')}),
        "exact": true,
        "title": "以房测评",
        "search": true
      }
    ]
  },
  {
    "path": "/activities",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__divLayout' */'@/layouts/divLayout')}),
    "routes": [
      {
        "path": "/activities/xingye",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__activities__xingye__xingye' */'@/pages/activities/xingye/xingye')}),
        "exact": true,
        "title": "兴业普惠云",
        "search": true
      }
    ]
  },
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__index' */'@/layouts/index')}),
    "routes": [
      {
        "path": "/",
        "redirect": "/index",
        "title": "首页",
        "bannerHeaderBG": true,
        "exact": true
      },
      {
        "path": "/index",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__index__index' */'@/pages/index/index')}),
        "exact": true,
        "title": "福建（长乐）纺织工业互联网平台",
        "search": true,
        "bannerHeaderBG": true
      },
      {
        "path": "/login",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__login-register__login__login' */'@/pages/login-register/login/login')}),
        "exact": true,
        "title": "登录",
        "search": true,
        "bannerHeaderBG": true
      },
      {
        "path": "/register",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__login-register__register__register' */'@/pages/login-register/register/register')}),
        "exact": true,
        "title": "注册",
        "search": true,
        "bannerHeaderBG": true
      },
      {
        "path": "/forget",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__login-register__forget__forget' */'@/pages/login-register/forget/forget')}),
        "exact": true,
        "title": "忘记密码",
        "search": true,
        "bannerHeaderBG": true
      },
      {
        "path": "/productMarket",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__productMarket__index__index' */'@/pages/productMarket/index/index')}),
        "exact": true,
        "title": "产品市场",
        "search": true,
        "bannerHeaderBG": true
      },
      {
        "path": "/productList",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__productMarket__productList__productList' */'@/pages/productMarket/productList/productList')}),
        "exact": true,
        "title": "产品列表",
        "search": true,
        "bannerHeaderBG": true
      },
      {
        "path": "/productDetail",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__productMarket__productDetail__productDetail' */'@/pages/productMarket/productDetail/productDetail')}),
        "exact": true,
        "title": "产品详情",
        "search": false,
        "bannerHeaderBG": true
      },
      {
        "path": "/solution",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__solution__solution' */'@/pages/solution/solution')}),
        "exact": true,
        "title": "解决方案",
        "search": true,
        "bannerHeaderBG": true
      },
      {
        "path": "/solution/details",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__solution__solutionDetail__solutionDetail' */'@/pages/solution/solutionDetail/solutionDetail')}),
        "exact": true,
        "title": "解决方案详情",
        "search": true,
        "bannerHeaderBG": false
      },
      {
        "path": "/government",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__government__index__government' */'@/pages/government/index/government')}),
        "exact": true,
        "title": "政企服务",
        "search": true,
        "bannerHeaderBG": true
      },
      {
        "path": "/government/policiesRegulations",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__government__policiesRegulations__policiesRegulations' */'@/pages/government/policiesRegulations/policiesRegulations')}),
        "exact": true,
        "title": "政策法规",
        "search": true,
        "bannerHeaderBG": false
      },
      {
        "path": "/government/projectDeclaration",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__government__projectDeclaration__projectDeclaration' */'@/pages/government/projectDeclaration/projectDeclaration')}),
        "exact": true,
        "title": "项目申报",
        "search": true,
        "bannerHeaderBG": false
      },
      {
        "path": "/government/newsNoticeDetails",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__government__news-notice-details__news-notice-details' */'@/pages/government/news-notice-details/news-notice-details')}),
        "exact": true,
        "title": "详情",
        "search": false,
        "bannerHeaderBG": false
      },
      {
        "path": "/aboutUs",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__aboutUs__aboutUs' */'@/pages/aboutUs/aboutUs')}),
        "exact": true,
        "title": "关于我们",
        "search": true,
        "bannerHeaderBG": true
      },
      {
        "path": "/ecologicalPartners",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ecologicalPartners__ecologicalPartners' */'@/pages/ecologicalPartners/ecologicalPartners')}),
        "exact": true,
        "title": "生态合作",
        "search": true,
        "bannerHeaderBG": true
      },
      {
        "path": "/identificationAnalysis",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__identity__identificationAnalysis__identificationAnalysis' */'@/pages/identity/identificationAnalysis/identificationAnalysis')}),
        "exact": true,
        "title": "标识解析",
        "search": true,
        "bannerHeaderBG": true
      },
      {
        "path": "/identityQuery",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__identity__identityQuery__identityQuery' */'@/pages/identity/identityQuery/identityQuery')}),
        "exact": true,
        "title": "标识查询",
        "search": false,
        "bannerHeaderBG": true
      },
      {
        "path": "/digitalTwin_factory",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__digitalTwin_10.12__digitalTwin_factory__digitalTwin_factory' */'@/pages/digitalTwin_10.12/digitalTwin_factory/digitalTwin_factory')}),
        "exact": true,
        "title": "孪生工厂（区）",
        "search": false,
        "bannerHeaderBG": true
      },
      {
        "path": "/digitalTwin_equipment",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__digitalTwin_10.12__digitalTwin_equipment__digitalTwin_equipment' */'@/pages/digitalTwin_10.12/digitalTwin_equipment/digitalTwin_equipment')}),
        "exact": true,
        "title": "孪生设备详情",
        "search": false,
        "bannerHeaderBG": true
      },
      {
        "path": "/digitalTwin_town",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__digitalTwin_10.12__digitalTwin_town__digitalTwin_town' */'@/pages/digitalTwin_10.12/digitalTwin_town/digitalTwin_town')}),
        "exact": true,
        "title": "孪生工厂（乡镇）",
        "search": false,
        "bannerHeaderBG": true
      },
      {
        "path": "/digitalTwin_workshop",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__digitalTwin_10.12__digitalTwin_workshop__digitalTwin_workshop' */'@/pages/digitalTwin_10.12/digitalTwin_workshop/digitalTwin_workshop')}),
        "exact": true,
        "title": "孪生工厂（车间）",
        "search": false,
        "bannerHeaderBG": true
      }
    ]
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
